/* This allows the graph to have coloured borders.
Based the colours from the FHT live website. */
.graphBorders {
    /* border-style: solid;
    border-width: 1px;
    border-color: whitesmoke;
    border-radius: 20px;
    background-color: white;
    line-height: 1px; */    
}

/* This is the heading of the graph, to change it to colour white */
.titleColor {
    color: black;
}

/* z-index to ensure the filter options are "above" all our graphs. */
.searchBar {
    z-index: 999;
 
}

/* This is to center the front page text. */
.centerFrontPageText {
    text-align: center;
}

/* This is so the practice dashboard has a greyish background. */
.practiceDashboardBackgroundColor {
    background-color: #F0F0F0;
}

/* This is for the practice dashboard heading to have a white background. */
.practiceDashboardHeadingBackgroundColor {
    background-color: white;
}