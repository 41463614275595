.rag-tick {
    content: "\2713";
    color: lightcoral;
  }
  .rag-cross {
    content: "\2717";
    color: lightgreen;
  }
  .rag-red {
    background-color: lightcoral;
  }
  .rag-green {
    background-color: lightgreen;
  }
  .rag-amber {
    background-color: lightsalmon;
  }
  .rag-white {
    background-color: white;
  }
  .rag-yellow {
    background-color: yellow;
  }
  
p-card {
    background-color:transparent;
}
.grid-container {
    display: grid;
    grid-gap: 5px;
    background-color: transparent;
    padding: 5px;
    width:100%;
    height:100%;
}
  
.grid-item {
  text-align: center;
  font-size: 12px;
  }
  
.glass-container {
    border-radius: 10px !important;
    background-color: rgba(136, 131, 131, 0.2);
/*    border: 1px solid rgba(255, 255, 255, 0.2)!important;*/
    border: #ffffff63 solid 2px!important;
    box-shadow: 6px 7px 5px #00000075!important;
}
.gridOptions {
  grid-column: 1 / span 6;
  grid-row: 1 / span 1; 
  display: grid;
}
.quickFilters {
    grid-column: 1 / span 1;
    grid-row: 1;
    background-color: transparent;
}

.selectAlgorithm {
    grid-column: 2 / span 3;
    grid-row: 1;
    background-color: transparent;
}
.printButton {
    grid-column: 5 / span 1;
    grid-row: 1;
    background-color: transparent;
}
 .datagrid {
  grid-column: 1 / span 6;
  grid-row: 2 / span 4; 
    background-color: transparent;
 }

 .selectOptionsChildCategory{
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: transparent;
 }
 .selectOptionsChildRisk{
    grid-column-start: 3;
    grid-column-end: 4;
    background-color: transparent;
 }