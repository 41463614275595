
.headingBorder {
  text-align: center;
}
  
.headingBorder:after {
  content: "";
  position: absolute;
  left: 4.5%;
  width: 40%;
  border-bottom: 1px solid white;
  z-index: 0;
}

.zIndex {
  z-index: -1;
}

/* This is to create the circle */
.halfCircle {
  height: 275px;
  width: 550px;
  border-radius: 0 0 550px 550px;
  background:rgb(63,167,194);
}

/* This is the tabbed section when highlighting.  */
div.tabbed {
  position: relative;
  font-size: 0;
}

div.tabbed > a {
  display: inline-block;
  padding: .5em;
  font-size: 16px;
  border-radius: 3px 3px 0 0;
  background-color: #333;
  color: #eee;
  text-decoration: none;
  line-height: 1em;
}

div.tabbed > a + a {
  margin-left: .5em;
}

div.tabbed > a:target {
  color: #333;
  background-color: #eee;
}

div.tabbed > a > div {
  position: absolute;
  top: 100%;
  left: 0;
  width: 300px;
  padding: .5em;
  border-radius: 0 3px 3px 3px;
  display: none;
  color: #333;
  background-color: #eee;
}

div.tabbed > a:hover > div {
  display: block;
}

.onHover:hover {
  background-color: #178583;
  transition: 0.5s;
}