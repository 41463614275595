/* This allows the graph to have coloured borders.
Based the colours from the FHT live website. */
.graphBorders {
    border-style: solid;
    border-width: 5px;
    border-color: #094183;
    border-radius: 20px;
    background-color: #094183;
    line-height: 1px;
}

.graphBordersTwo {
    border-style: solid;
    border-width: 5px;
    border-radius: 10px;
    border-color: black;
    background-color: white;

}



/* This is the heading of the graph, to change it to colour white */
.titleColor {
    color: white;
}

/* z-index to ensure the filter options are "above" all our graphs. */
.searchBar {
    position: absolute;
    z-index: 999;
}

.searchBarTwo {
    position: absolute;
    z-index: 888;
}

.searchBarThree {
    position: absolute;
    z-index: 777;
}

.searchBarFour {
    position: absolute;
    z-index: 666;
}

.zAxis {
    z-index: 0;
}

/* This is to center the front page text. */
.centerFrontPageText {
    text-align: center;
}

/* This is so the practice dashboard has a greyish background. */
.practiceDashboardBackgroundColor {
    background-color: #F0F0F0;
}

/* This is for the practice dashboard heading to have a white background. */
.practiceDashboardHeadingBackgroundColor {
    background-color: white;
}
