.App {
  /* text-align: center; */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* //////////////////////
// TAG: MEGA MENU CODE
////////////////////// */

.containerPropStyle {
  /* border-style: solid;
  color: green; */
  background-color:#0A4182;
  /* Change this if there shouldn't be a entire colour page */
  width: 130%;
  /* Could use this to move, but it does become messy with the width value above. 
  right: 100px; */
  top: 80%;
  right: -30%;
  z-index: 99999;
  display: flex;
  align-items: stretch;


}

.contentPropStyle {
  /* border-style: solid;
  color: blue; */
  color: white;
  width: 100%;
  padding: 15px;
}

.menuItemPropStyle {
  /* border-style: solid;
  color: black; */
  color: white;
  width: 200px;
  height: 50px;
  text-align: center;
  padding: 15px;

}

.menuItemSelectedPropStyle {
  /* border-style: solid;
  color: red; */
  color: white;
  width: 200px;
  height: 50px;
  padding: 15px;
  text-align: center;
  /*background-color: rgb(12, 74, 151);*/
  background-color:#279593;
}

.zIndex {
  z-index: -99999;
}

.testtest {
  background-color: red;
}
/*  */
/* Dropdown Button */
.dropbtn {
  background-color: #0A4182;
  color: white;
  vertical-align: baseline;
  
  font-size: 16px;
  border: none;
  padding-top: 23px;
  padding-bottom: 23px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;  
  /*float: left;*/
  /*overflow:hidden;*/
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  width:100%;
  margin-left:3REM;
  display: none;
  position: absolute;
  padding-left: 1REM;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999999;
  color: white;
  /*background-color: #0A4182;*/
  /*background-color:#279593;*/
  /*background-color:linear-gradient(to right, #279593, #489594);*/
  background:linear-gradient(to right, #279593, #48AF68);
  /*background-color:linear-gradient(to right,yellow, red);*/
}

/* Links inside the dropdown */
.dropdown-content a {
 /* position:relative;*/
 width:100%;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  /*background-color: rgb(10, 50, 99);*/
  background-color:#077573;
  color: white;
  transition: 0.5s;

}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.dropdown:click .dropdown-content {display: none;}


/* Change the background color of the dropdown button when the dropdown content is shown */
/*.dropdown:hover .dropbtn {background-color: rgb(10, 50, 99);}*/
.dropdown:hover .dropbtn {background-color: #279593;}

.dropdowntorch {
  position: relative;
  display: inline-block;  
  /*float: left;*/
  /*overflow:hidden;*/
}
/* Dropdown Content (Hidden by Default) torch */
.dropdowntorch-content {
  width:100%;
  margin-left:3REM;
  display: none;
  position: absolute;
  padding-left: 1REM;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999999;
  color: white;
  /*background-color: #0A4182;*/
  /*background-color:#279593;*/
  /*background-color:linear-gradient(to right, #279593, #489594);*/
  background:linear-gradient(to right, #279593, #48AF68);
  /*background-color:linear-gradient(to right,yellow, red);*/
}
/* Links inside the dropdown */
.dropdowntorch-content a {
 /* position:relative;*/
 width:100%;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdowntorch-content a:hover {
  /*background-color: rgb(10, 50, 99);*/
  /* background-color:#077573; */
  background-color:#f14d30;
  color: white;
  transition: 0.5s;
}
/* Show the dropdown menu on hover */
.dropdowntorch:hover .dropdowntorch-content {display: block;}
.dropdowntorch:click .dropdowntorch-content {display: none;}
/* Change the background color of the dropdown button when the dropdown content is shown */
/*.dropdown:hover .dropbtn {background-color: rgb(10, 50, 99);}*/
.dropdowntorch:hover .dropbtn {background-color: #279593;}
.column {
  float: left;
  width: 33%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.menuBorderBottom {
  margin: 0 0 0px;
  padding: 0 0 0px;
  position: relative;
}
/*
.menuBorderBottom::after {
  background-color: #efefef; 
  bottom: 0;
  content: '';
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translate(-50%,0);
  width: 90%;
}
*/
.line{
  text-align:left;
  /*width: 18REM;*/
  border-bottom: 2px solid white;
  transform: translate(-1REM,0);
  font-weight:bold;
  font-size:1.1REM;
  height:3.3REM;
  /*position: absolute;*/
  }

  .menuDescription{
    text-align:left;
    /*width: 13REM;*/
    margin-top: 0.3REM;
    transform: translate(-1REM,0);
    height:8REM;
  }

.threeColumns {
  float: left;
  width: 33.3333%;
}

.twoColumns {
  float: left;
  width: 50%;
}

.oneColumn {
  float: left;
  width: 100%;
}

.fourColumns {
  float: left;
  width: 25%;
  padding-left: 1REM;
}

.disableHover{
  pointer-events: none;
}
.tox-notifications-container {
  display: none !important;
}
.tox-statusbar {
  display: none !important;
}
