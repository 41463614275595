#guideline {
    font-size: 0.8em;
    color:black;
    /* padding: 10px; */
    /* overflow-wrap: break-word; */
    word-wrap: break-word; 
}

#guideline ol, #guideline ul {
    margin-left:0em;
    padding-inline-start: 20px;
    /* font-size:95%; */
}

#guideline p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.9em;
}

#guideline ol li {
    font-weight: 500;
    color: dodgerblue;
    font-size: 1.5em;
}

#guideline ol li div {
    font-weight: normal;
    color:black;
}

#guideline ol li div ul li {
    font-weight: normal;
    color:black;
    /* font-size: 1em; */
    font-size: 0.85em;
}

#guideline ol li div ol li {
    font-weight: normal;
    color:black;
    font-size: 0.85em;
}

#guideline a {
    text-decoration: none;
    color: darkblue;
}
#guideline a:visited {
    text-decoration: none;
    color: darkblue;
}

#guideline a:hover {
    color:black;
    background:yellow;
}

.columnImage {
    float: left;
    width: 50%;
}

 .columnText {
    float: left;
    width: 100%;
 }
  

#guidelines a {
    text-decoration: none;
    color: darkblue;
}
#guidelines a:visited {
    text-decoration: none;
    color: darkblue;
}

#guidelines a:hover {
    color:black;
    background:yellow;
}

.column {
    float: left;
    width: 50%;
    /* border: 1px;
    border-style: solid; */
    padding: 20px;
    text-align: center;
  }
  
.row:after {
    content: "";
    display: table;
    clear: both;
  }