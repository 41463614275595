@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/

body {
  margin: 0;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  /* text-align: center; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* //////////////////////
// TAG: MEGA MENU CODE
////////////////////// */

.containerPropStyle {
  /* border-style: solid;
  color: green; */
  background-color:#0A4182;
  /* Change this if there shouldn't be a entire colour page */
  width: 130%;
  /* Could use this to move, but it does become messy with the width value above. 
  right: 100px; */
  top: 80%;
  right: -30%;
  z-index: 99999;
  display: flex;
  align-items: stretch;


}

.contentPropStyle {
  /* border-style: solid;
  color: blue; */
  color: white;
  width: 100%;
  padding: 15px;
}

.menuItemPropStyle {
  /* border-style: solid;
  color: black; */
  color: white;
  width: 200px;
  height: 50px;
  text-align: center;
  padding: 15px;

}

.menuItemSelectedPropStyle {
  /* border-style: solid;
  color: red; */
  color: white;
  width: 200px;
  height: 50px;
  padding: 15px;
  text-align: center;
  /*background-color: rgb(12, 74, 151);*/
  background-color:#279593;
}

.zIndex {
  z-index: -99999;
}

.testtest {
  background-color: red;
}
/*  */
/* Dropdown Button */
.dropbtn {
  background-color: #0A4182;
  color: white;
  vertical-align: baseline;
  
  font-size: 16px;
  border: none;
  padding-top: 23px;
  padding-bottom: 23px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;  
  /*float: left;*/
  /*overflow:hidden;*/
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  width:100%;
  margin-left:3REM;
  display: none;
  position: absolute;
  padding-left: 1REM;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999999;
  color: white;
  /*background-color: #0A4182;*/
  /*background-color:#279593;*/
  /*background-color:linear-gradient(to right, #279593, #489594);*/
  background:linear-gradient(to right, #279593, #48AF68);
  /*background-color:linear-gradient(to right,yellow, red);*/
}

/* Links inside the dropdown */
.dropdown-content a {
 /* position:relative;*/
 width:100%;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  /*background-color: rgb(10, 50, 99);*/
  background-color:#077573;
  color: white;
  transition: 0.5s;

}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.dropdown:click .dropdown-content {display: none;}


/* Change the background color of the dropdown button when the dropdown content is shown */
/*.dropdown:hover .dropbtn {background-color: rgb(10, 50, 99);}*/
.dropdown:hover .dropbtn {background-color: #279593;}

.dropdowntorch {
  position: relative;
  display: inline-block;  
  /*float: left;*/
  /*overflow:hidden;*/
}
/* Dropdown Content (Hidden by Default) torch */
.dropdowntorch-content {
  width:100%;
  margin-left:3REM;
  display: none;
  position: absolute;
  padding-left: 1REM;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999999;
  color: white;
  /*background-color: #0A4182;*/
  /*background-color:#279593;*/
  /*background-color:linear-gradient(to right, #279593, #489594);*/
  background:linear-gradient(to right, #279593, #48AF68);
  /*background-color:linear-gradient(to right,yellow, red);*/
}
/* Links inside the dropdown */
.dropdowntorch-content a {
 /* position:relative;*/
 width:100%;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* Change color of dropdown links on hover */
.dropdowntorch-content a:hover {
  /*background-color: rgb(10, 50, 99);*/
  /* background-color:#077573; */
  background-color:#f14d30;
  color: white;
  transition: 0.5s;
}
/* Show the dropdown menu on hover */
.dropdowntorch:hover .dropdowntorch-content {display: block;}
.dropdowntorch:click .dropdowntorch-content {display: none;}
/* Change the background color of the dropdown button when the dropdown content is shown */
/*.dropdown:hover .dropbtn {background-color: rgb(10, 50, 99);}*/
.dropdowntorch:hover .dropbtn {background-color: #279593;}
.column {
  float: left;
  width: 33%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.menuBorderBottom {
  margin: 0 0 0px;
  padding: 0 0 0px;
  position: relative;
}
/*
.menuBorderBottom::after {
  background-color: #efefef; 
  bottom: 0;
  content: '';
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translate(-50%,0);
  width: 90%;
}
*/
.line{
  text-align:left;
  /*width: 18REM;*/
  border-bottom: 2px solid white;
  -webkit-transform: translate(-1REM,0);
          transform: translate(-1REM,0);
  font-weight:bold;
  font-size:1.1REM;
  height:3.3REM;
  /*position: absolute;*/
  }

  .menuDescription{
    text-align:left;
    /*width: 13REM;*/
    margin-top: 0.3REM;
    -webkit-transform: translate(-1REM,0);
            transform: translate(-1REM,0);
    height:8REM;
  }

.threeColumns {
  float: left;
  width: 33.3333%;
}

.twoColumns {
  float: left;
  width: 50%;
}

.oneColumn {
  float: left;
  width: 100%;
}

.fourColumns {
  float: left;
  width: 25%;
  padding-left: 1REM;
}

.disableHover{
  pointer-events: none;
}
.tox-notifications-container {
  display: none !important;
}
.tox-statusbar {
  display: none !important;
}

/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

 .CircularProgressbar {
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     *
     * If you're not using "display: flex", you can remove this style.
     */
    width: 100%;
  }
  
  .CircularProgressbar .CircularProgressbar-path {
    stroke: #3e98c7;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6;
  }
  
  .CircularProgressbar .CircularProgressbar-text {
    fill: #3e98c7;
    font-size: 12px;
    display: inline-block;
    dominant-baseline: middle;
    text-anchor: middle;
  }
  
  .CircularProgressbar .CircularProgressbar-background {
    fill: #d6d6d6;
  }
  
  /*
   * Sample background styles. Use these with e.g.:
   *
   *   <CircularProgressbar
   *     className="CircularProgressbar-inverted"
   *     background
   *     percentage={50}
   *   />
   */
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
    fill: #3e98c7;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
  }
  

.headingBorder {
  text-align: center;
}
  
.headingBorder:after {
  content: "";
  position: absolute;
  left: 4.5%;
  width: 40%;
  border-bottom: 1px solid white;
  z-index: 0;
}

.zIndex {
  z-index: -1;
}

/* This is to create the circle */
.halfCircle {
  height: 275px;
  width: 550px;
  border-radius: 0 0 550px 550px;
  background:rgb(63,167,194);
}

/* This is the tabbed section when highlighting.  */
div.tabbed {
  position: relative;
  font-size: 0;
}

div.tabbed > a {
  display: inline-block;
  padding: .5em;
  font-size: 16px;
  border-radius: 3px 3px 0 0;
  background-color: #333;
  color: #eee;
  text-decoration: none;
  line-height: 1em;
}

div.tabbed > a + a {
  margin-left: .5em;
}

div.tabbed > a:target {
  color: #333;
  background-color: #eee;
}

div.tabbed > a > div {
  position: absolute;
  top: 100%;
  left: 0;
  width: 300px;
  padding: .5em;
  border-radius: 0 3px 3px 3px;
  display: none;
  color: #333;
  background-color: #eee;
}

div.tabbed > a:hover > div {
  display: block;
}

.onHover:hover {
  background-color: #178583;
  transition: 0.5s;
}
#guideline {
    font-size: 0.8em;
    color:black;
    /* padding: 10px; */
    /* overflow-wrap: break-word; */
    word-wrap: break-word; 
}

#guideline ol, #guideline ul {
    margin-left:0em;
    -webkit-padding-start: 20px;
            padding-inline-start: 20px;
    /* font-size:95%; */
}

#guideline p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.9em;
}

#guideline ol li {
    font-weight: 500;
    color: dodgerblue;
    font-size: 1.5em;
}

#guideline ol li div {
    font-weight: normal;
    color:black;
}

#guideline ol li div ul li {
    font-weight: normal;
    color:black;
    /* font-size: 1em; */
    font-size: 0.85em;
}

#guideline ol li div ol li {
    font-weight: normal;
    color:black;
    font-size: 0.85em;
}

#guideline a {
    text-decoration: none;
    color: darkblue;
}
#guideline a:visited {
    text-decoration: none;
    color: darkblue;
}

#guideline a:hover {
    color:black;
    background:yellow;
}

.columnImage {
    float: left;
    width: 50%;
}

 .columnText {
    float: left;
    width: 100%;
 }
  

#guidelines a {
    text-decoration: none;
    color: darkblue;
}
#guidelines a:visited {
    text-decoration: none;
    color: darkblue;
}

#guidelines a:hover {
    color:black;
    background:yellow;
}

.column {
    float: left;
    width: 50%;
    /* border: 1px;
    border-style: solid; */
    padding: 20px;
    text-align: center;
  }
  
.row:after {
    content: "";
    display: table;
    clear: both;
  }
/* This allows the graph to have coloured borders.
Based the colours from the FHT live website. */
.graphBorders {
    /* border-style: solid;
    border-width: 1px;
    border-color: whitesmoke;
    border-radius: 20px;
    background-color: white;
    line-height: 1px; */    
}

/* This is the heading of the graph, to change it to colour white */
.titleColor {
    color: black;
}

/* z-index to ensure the filter options are "above" all our graphs. */
.searchBar {
    z-index: 999;
 
}

/* This is to center the front page text. */
.centerFrontPageText {
    text-align: center;
}

/* This is so the practice dashboard has a greyish background. */
.practiceDashboardBackgroundColor {
    background-color: #F0F0F0;
}

/* This is for the practice dashboard heading to have a white background. */
.practiceDashboardHeadingBackgroundColor {
    background-color: white;
}
/* This allows the graph to have coloured borders.
Based the colours from the FHT live website. */
.graphBorders {
    border-style: solid;
    border-width: 5px;
    border-color: #094183;
    border-radius: 20px;
    background-color: #094183;
    line-height: 1px;
}

.graphBordersTwo {
    border-style: solid;
    border-width: 5px;
    border-radius: 10px;
    border-color: black;
    background-color: white;

}



/* This is the heading of the graph, to change it to colour white */
.titleColor {
    color: white;
}

/* z-index to ensure the filter options are "above" all our graphs. */
.searchBar {
    position: absolute;
    z-index: 999;
}

.searchBarTwo {
    position: absolute;
    z-index: 888;
}

.searchBarThree {
    position: absolute;
    z-index: 777;
}

.searchBarFour {
    position: absolute;
    z-index: 666;
}

.zAxis {
    z-index: 0;
}

/* This is to center the front page text. */
.centerFrontPageText {
    text-align: center;
}

/* This is so the practice dashboard has a greyish background. */
.practiceDashboardBackgroundColor {
    background-color: #F0F0F0;
}

/* This is for the practice dashboard heading to have a white background. */
.practiceDashboardHeadingBackgroundColor {
    background-color: white;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
  line-height:150%;
}

.rag-tick {
    content: "\2713";
    color: lightcoral;
  }
  .rag-cross {
    content: "\2717";
    color: lightgreen;
  }
  .rag-red {
    background-color: lightcoral;
  }
  .rag-green {
    background-color: lightgreen;
  }
  .rag-amber {
    background-color: lightsalmon;
  }
  .rag-white {
    background-color: white;
  }
  .rag-yellow {
    background-color: yellow;
  }
  
p-card {
    background-color:transparent;
}
.grid-container {
    display: grid;
    grid-gap: 5px;
    background-color: transparent;
    padding: 5px;
    width:100%;
    height:100%;
}
  
.grid-item {
  text-align: center;
  font-size: 12px;
  }
  
.glass-container {
    border-radius: 10px !important;
    background-color: rgba(136, 131, 131, 0.2);
/*    border: 1px solid rgba(255, 255, 255, 0.2)!important;*/
    border: #ffffff63 solid 2px!important;
    box-shadow: 6px 7px 5px #00000075!important;
}
.gridOptions {
  grid-column: 1 / span 6;
  grid-row: 1 / span 1; 
  display: grid;
}
.quickFilters {
    grid-column: 1 / span 1;
    grid-row: 1;
    background-color: transparent;
}

.selectAlgorithm {
    grid-column: 2 / span 3;
    grid-row: 1;
    background-color: transparent;
}
.printButton {
    grid-column: 5 / span 1;
    grid-row: 1;
    background-color: transparent;
}
 .datagrid {
  grid-column: 1 / span 6;
  grid-row: 2 / span 4; 
    background-color: transparent;
 }

 .selectOptionsChildCategory{
    grid-column-start: 1;
    grid-column-end: 2;
    background-color: transparent;
 }
 .selectOptionsChildRisk{
    grid-column-start: 3;
    grid-column-end: 4;
    background-color: transparent;
 }
/* package default styles */
.component-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
  .component-slider .slider-container {
    margin: 0 20px;
    overflow: hidden;
  }
  .component-slider .slider-content {
    display: inline-block;
    transition: margin-left 0.15s ease-in;
  }
  .component-slider .caret {
    width: 20px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    color: #797979;
  }
  .component-slider .caret-right {
    right: 0;
  }
  .component-slider .caret-left {
    left: 0;
  }
  
  /*custom styles*/
  .menu-bar {
    border: 1px solid gray;
  }
  
  .menu-item {
    width: 500px;
    display: inline-block;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: uppercase;
    padding-top:-10px;
  }
  .menu-item-disabled-left {
    width: 100px;
    display: inline-block;
    padding-left:-2rem;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: uppercase;
    padding-top:-10px;
  }
  .menu-item-disabled-right {
    width: 100px;
    display: inline-block;
    padding-right:10px;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: uppercase;
    padding-top:-10px;
  }  
  .menu-item:hover {
    background: #656565;
    color: #ffffff;
  }
  
/* This alters the width of the "setFilter" pop out filters used in the
Datagrid component */
.ag-virtual-list-viewport {
    width: 400px;
} 
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
