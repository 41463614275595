/* package default styles */
.component-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
  .component-slider .slider-container {
    margin: 0 20px;
    overflow: hidden;
  }
  .component-slider .slider-content {
    display: inline-block;
    transition: margin-left 0.15s ease-in;
  }
  .component-slider .caret {
    width: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    color: #797979;
  }
  .component-slider .caret-right {
    right: 0;
  }
  .component-slider .caret-left {
    left: 0;
  }
  
  /*custom styles*/
  .menu-bar {
    border: 1px solid gray;
  }
  
  .menu-item {
    width: 500px;
    display: inline-block;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: uppercase;
    padding-top:-10px;
  }
  .menu-item-disabled-left {
    width: 100px;
    display: inline-block;
    padding-left:-2rem;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: uppercase;
    padding-top:-10px;
  }
  .menu-item-disabled-right {
    width: 100px;
    display: inline-block;
    padding-right:10px;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: uppercase;
    padding-top:-10px;
  }  
  .menu-item:hover {
    background: #656565;
    color: #ffffff;
  }
  